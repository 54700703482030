import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Divider } from "@mui/material";
import { useGetShiftFeedbackClockOutConfig } from "@src/appV2/Shifts/Shift/useGetShiftFeedbackClockOutConfig";

import { WorkplaceFeedbackData } from "./types";

type WorkplaceFeedbackQuestionProps = {
  onFeedbackDataUpdate: (data: WorkplaceFeedbackData) => void;
  facilityId: string;
  feedbackData?: WorkplaceFeedbackData;
  showError: boolean;
  setShowError: (showError: boolean) => void;
};

export function WorkplaceFeedbackQuestion(props: WorkplaceFeedbackQuestionProps) {
  const { onFeedbackDataUpdate, feedbackData, facilityId, showError, setShowError } = props;
  const { questions } = useGetShiftFeedbackClockOutConfig(facilityId);
  const { responses = {}, responseTypes = {} } = feedbackData ?? {};

  const handleResponseSelect = (index: number, value: string, type?: string) => {
    setShowError(false);
    const updatedResponses = { ...responses, [index]: value };
    const updatedResponseTypes = type ? { ...responseTypes, [index]: type } : responseTypes;

    onFeedbackDataUpdate({
      responses: updatedResponses,
      responseTypes: updatedResponseTypes,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {questions.map((question, index) => (
        <Box key={`question-${question.description}`}>
          {isDefined(question.description) && (
            <>
              <Box aria-label={`Question ${index + 1}`}>
                <Text mb={1}>{question.description}</Text>
                <Box display="flex" flexDirection="column" gap={1}>
                  {question.options.map(({ value, type }) => (
                    <Button
                      key={value}
                      variant={responses[index] === value ? "contained" : "outlined"}
                      color="primary"
                      fullWidth
                      onClick={() => handleResponseSelect(index, value, type)}
                      size="small"
                    >
                      {value}
                    </Button>
                  ))}
                </Box>
              </Box>
              {index < questions.length - 1 && <Divider sx={{ my: 2 }} />}
            </>
          )}
        </Box>
      ))}

      {showError && (
        <Text color="error" mt={1} variant="body2">
          *Please select an option {questions.length > 1 ? "for all questions" : ""}
        </Text>
      )}

      <Text variant="body2" color={(theme) => theme.palette.grey[600]}>
        Your response is anonymous and shared with your workplace administrators only.
      </Text>
    </Box>
  );
}
